// src/cms/app/components/model-page/index
.draggable-tree-single li span.ant-tree-switcher, .draggable-tree-single li span.ant-tree-iconEle {
  width: 0!important;
}
.draggable-tree-single.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: 100%!important;
}
.float-img-down-upload {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.float-img-down-upload .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
}
.self-img-upload {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.self-img-upload .ant-upload.ant-upload-select-picture-card {
  display: block;
  width: 100%;
  height: 100%;
}
.self-img-upload .ant-upload.ant-upload-select-picture-card span {
  display: block;
  width: 100%;
  height: 100%;
}
